<template>
	<div
		class="flex-column full-height"
	>
		<Title
			:program="program"
		></Title>

		<div
			class="pa-10 flex-column full-height overflow-y-auto bg-gray-light"
		>
			<div>
				<div class="justify-space-between">
					<h6 class="pa-10 ">내 정보</h6>
				</div>
				<ul
					class="box-mypage-list shadow ul-under-line size-px-14 bg-white"
				>
					<li
						class="pa-10 justify-space-between"
					>
						<span>아이디</span>
						<span>{{ user.account_id }}</span>
					</li>
					<li
						class="pa-10 justify-space-between bg-gray-light cursor-pointer"
						@click="toPassword"
					>
						<span>비밀번호 변경</span>
						<span><v-icon>mdi-chevron-right</v-icon></span>
					</li>
					<li
						class="pa-10 justify-space-between"
					>
						<span>이름</span>
						<span>{{ user.account_name }}</span>
					</li>
					<li
						class="pa-10 justify-space-between"
					>
						<span>연락처</span>
						<span>{{ user.account_phone_number }}</span>
					</li>
					<li
						class="pa-10 text-right size-px-12 justify-space-between bg-gray-light cursor-pointer"
						@click="toSms"
					>
						<span>SMS 인증</span>
						<div>
							<span v-if="!user.sms_auth_uid" class="label label-danger">미인증</span>
							<span v-else class="label label-success">인증 완료</span>
							<v-icon v-if="!user.sms_auth_uid" >mdi-chevron-right</v-icon>
						</div>
					</li>
					<li
						class="pa-10"
					>
						<div class="text-right size-px-12 "> 연락처 변경은 관리자에게 문의하세요</div>
					</li>
					<li
						class="pa-10 justify-space-between bg-gray-light cursor-pointer"
						@click="onPin"
					>
						<span>PIN 설정</span>
						<span><v-icon>mdi-chevron-right</v-icon></span>
					</li>
				</ul>

				<h6
					v-if="false"
					class="pa-10 mt-10"
				>고객센터</h6>
				<ul
					v-if="false"
					class="box-mypage-list shadow ul-under-line size-px-14 bg-white"
				>
					<li
						class="pa-10 justify-space-between cursor-pointer"
						@click="toNotice"
					>
						<span>공지사항</span>
						<span><v-icon>mdi-chevron-right</v-icon></span>
					</li>
					<li
						class="pa-10 justify-space-between cursor-pointer"
						@click="toQnA"
					>
						<span>1:1 문의</span>
						<span><v-icon>mdi-chevron-right</v-icon></span>
					</li>
				</ul>

				<div class="mt-50">
					<button class="btn btn-primary" @click="logout">로그아웃</button>
				</div>
			</div>

		</div>
	</div>
</template>

<script>

import Title from "@/view/Layout/Title";

export default{
	name: 'Mypage'
	,props: ['user']
	,components: { Title }
	,data: function(){
		return {
			program: {
				name: '마이페이지'
				,code: 'mypage'
				,wrap: 'mypage'
				,top: false
				,title: false
				,bottom: true
				,class: 'blue'
			}
			,item: {
				member_id: '아이디'
				,member_phone: '연락처'
				,member_email: '이메일'
				,member_post: '우편번호'
				,member_addr1: '주소'
				,member_addr2: '상세 주소'
			}
			,view: {
				password: false
			}
		}
	}
	,methods: {
		toPassword: function(){
			this.$bus.$emit('to', { name: 'Password'})
		}
		, toQnA: function(){
			this.$bus.$emit('to', { name: 'CustomerCenter', params: { b_code: 'b_qna'}})
		}
		, toNotice: function(){
			this.$bus.$emit('to', { name: 'CustomerCenter', params: { b_code: 'b_notice'}})
		}
		, toModify: function(){
			this.$bus.$emit('to', { name: 'Modify'})
		}
		, toSms: function(){
			this.$bus.$emit('to', { name: 'SmsConfirmRequest'})
		}
		, onPin: function(){
			this.$bus.$off('pinCallback')

			this.$bus.$emit('onPin', {
				type: this.user.is_pin > 0 ? 'check' : 'new'
			})

			this.$bus.$on('pinCallback', (call) => {
// console.log('pinCallback', call)
				switch (call.option.type){
					case 'new':
						this.postPin(call)
						break;
					case 'check':
						this.postPinCheck(call)
					break;
				}
			})
		}
		, onPinUpdate: function(){
			this.$bus.$off('pinCallback')

			this.$bus.$emit('onPin', {
				type: 'update'
			})
			this.$bus.$on('pinCallback', (call) => {
// console.log('pinCallback', call)
				switch (call.option.type){
					case 'update':
						this.postPin(call)
					break;
				}
			})
		}
		, postPinCheck: async function(data){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: this.$api_url.api_path.post_pin_check
					, data: {
						account_id: this.user.account_id
						, pin: data.pin
					}
				})
				if(result.success){
					this.onPinUpdate()
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postPin: async function(data){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: data.option.type == 'new' ? this.$api_url.api_path.post_pin : this.$api_url.api_path.put_pin
					, data: {
						account_id: this.user.account_id
						, pin: data.pin
						, pin_confirm: data.pin_confirm
					}
				})
				if(result.success){
					this.$bus.$emit('updateUser', result.data)
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					this.$bus.$emit('offPin')
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, logout: function(){
			let auto_login = localStorage.getItem(process.env.VUE_APP_NAME + 'A')

			if(auto_login) {

				if(confirm('로그아웃시 자동로그인도 해제됩니다. 로그아웃 하시겠습니까?')) {

					localStorage.removeItem(process.env.VUE_APP_NAME + 'A')
					localStorage.removeItem(process.env.VUE_APP_NAME + 'N')
					localStorage.removeItem(process.env.VUE_APP_NAME + 'P')

					sessionStorage.clear()

					this.$bus.$emit('to', { name: 'Login', type: 'href'})
				}

			}else{

				if(confirm('로그아웃 하시겠습니까?')){
					sessionStorage.clear()
					this.$bus.$emit('to', { name: 'Login', type: 'href'})
				}
			}
		}
	}
	,created: function(){
		this.$bus.$emit('onLoad', this.program)
	}
	,watch: {
	}
}
</script>

<style>
.box-mypage-list {}
.box-mypage-list.shadow { box-shadow: 0px 3px 5px rgb(0, 0, 0, .3) }
.btn-mypage-add {
	border: 1px solid #ddd;
	padding: 2px 2px 2px 7px;
	font-size: 11px
}
</style>